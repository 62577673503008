<template>
  <div>
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- Project -->
        <b-col cols="12">
          <b-card
            :title="project.name"
          >
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="24"
                  class="mr-50 text-primary"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">{{ formatDate(project.start_date) }}</small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ formatDate(project.end_date) }}</small>
              </b-media-body>
            </b-media>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content  mt-2"
              v-html="project.description"
            />
            <!-- eslint-enable -->
            <hr class="my-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                      icon="UsersIcon"
                      size="21"
                      class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ project.project_users.length }}
                    </div>
                  </b-link>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <!--/ Project -->
      </b-row>
    </div>
    <b-row>
      <!-- Survey & Games -->
      <b-col cols="6">
        <b-card>
          <project-survey-view
            :surveys="project.surveys"
          />
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <profile-game
            :header-text="$t('GAMES')"
            :games="project.competitions"
          />
        </b-card>
      </b-col>
      <!-- Survey & Games -->
    </b-row>
    <!-- Users -->
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <b-row class="mb-1">
              <b-col
                cols="6"
                xl="6"
                md="6"
              >
                <b-input-group class="input-group-merge flex-grow-1">
                  <b-input-group-prepend is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchUserQuery"
                    :placeholder="$t('SEARCH_PARTICIPANTS')"
                    class="flex-grow-1"
                  />
                </b-input-group>
              </b-col>
              <b-col
                cols="6"
                xl="6"
                md="6"
              >
                <b-form-group label-for="id-categories">
                  <v-select
                    id="id-company"
                    v-model="company"
                    :placeholder="$t('SELECT_COMPANY')"
                    label="companyname"
                    :clearable="false"
                    class="flex-grow-1"
                    :options="companies"
                    @input="handleChangeCompany($event)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-card-text>
                  <div style="display: flex; align-items: center;">
                    <span> {{ $t("AVAILABLE_PARTICIPANTS") }} </span>
                    <b-button
                      variant="primary"
                      class="ml-1"
                      @click="openProjectUserModel()"
                    >
                      <span>{{ $t('ADD_PROJECT_MEMBER') }} </span>
                      <feather-icon icon="PlusCircleIcon" />
                    </b-button>
                    <b-button
                      variant="primary"
                      class="ml-1"
                      @click="uploadAllCompanyUsers()"
                    >
                      <span>{{ $t('ALL') }} </span>
                      <feather-icon icon="PlusCircleIcon" />
                    </b-button>
                  </div>
                </b-card-text>
                <b-list-group
                  v-for="user in selectdUsers"
                  :key="user.userID"
                >
                  <b-list-group-item class="d-flex">
                    <b-form-checkbox
                      :id="'chk_user' + user.userID.toString()"
                      :checked="user.checked || false"
                      :disabled="user.checked"
                      @change="handleUserChange(user)"
                    />
                    <div>
                      <b-avatar
                        size="32"
                        :text="
                          avatarText(
                            user.firstname +
                              ' ' +
                              user.lastname
                          )
                        "
                        :variant="`light-${
                          resolveUserRole(user.userRoleId).variant
                        }`"
                        :to="{
                          name: 'user-profile-id',
                          params: { id: user.userID },
                        }"
                      />
                      <span class="mr-1 mb-1">
                        {{ user.firstname }}
                        {{ user.lastname }}
                      </span>
                      <small class="text-muted d-block text-nowrap ml-3">{{ user.email }}   </small>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <span
                  class="text-muted mt-1"
                >{{ $t("SHOWING") }} {{ selectdUsers.length }} of
                  {{ allUsers.length }} {{ $t("ENTRIES") }}</span>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col lg="12">
                <b-card-text class="mt-1">
                  <span> {{ $t("PROJECT_PARTICIPANTS") }} </span>
                </b-card-text>
                <project-user-view
                  :project-users="project.project_users"
                  :delete-user="handleDeleteUser"
                  :project-user-edit="openProjectUserModel"
                />

              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <project-user-model
      :project-user-save="handleUserChange"
      :project-user-edit="handleEditUser"
      :company="company"
      :edit-project-user="projectUser"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BInputGroupPrepend,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BInputGroup,
  BListGroup,
  BListGroupItem,
  BCardText,
  BAvatar,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BButton,
  VBPopover,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { formatDate, avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinList } from '@/constants/mixinValidations'
import { resolveUserRole } from '@/constants/utils'
import ProfileGame from '../../user/user-profile/ProfileGame.vue'
import ProjectSurveyView from './ProjectSurveyView.vue'
import ProjectUserView from './ProjectUserView.vue'
import ProjectUserModel from './ProjectUserModel.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormInput,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BListGroup,
    BListGroupItem,
    BCardText,
    BAvatar,
    BLink,
    BFormGroup,
    BMedia,
    BMediaAside,
    BMediaBody,
    vSelect,
    BButton,
    ProfileGame,
    ProjectSurveyView,
    ProjectUserView,
    ProjectUserModel,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  // eslint-disable-next-line no-dupe-keys
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      project: {
        ID: 0,
        name: '',
        start_date: '',
        end_date: '',
        company_id: 0,
        description: '',
        project_users: [],
        surveys: [],
        competitions: [],
      },
      maxUserId: 0,
      searchUserQuery: '',
      selectdUsers: [],
      allUsers: [],
      company: {
        companyID: 0,
        companyname: '',
      },
      projectUser: {},
      companies: [],
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      formatDate,
      resolveUserRole,
      avatarText,
    }
  },
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
    addProjectUserTooltip() {
      return this.$i18n.t('TOOLTIP.PROJECT_USER_ADD')
    },
    addAllProjectUserTooltip() {
      return this.$i18n.t('TOOLTIP.PROJECT_USER_ALL_ADD')
    },
  },
  watch: {
    searchUserQuery: 'filterUsersByConditions',
  },
  async created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.project.ID = parseInt(router.currentRoute.params.id)
      if (this.project.ID !== 0) {
        this.fetchProjectByID(this.project.ID).then(async response => {
          if (response) {
            this.project = response.data
            this.maxUserId = this.getMaxUserId(this.project)
            await this.fetchUsers(this.project.company_id)
            this.updateAllUsersWithCheckedProperty()
            await this.fetchCompany()
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('user', ['fetchUsersByCompanyID']),
    ...mapActions('project', [
      'createProjectUser',
      'updateProjectUser',
      'deleteProjectUser',
      'fetchProjectByID',
      'uploadAllCompanyUsersToProject',
    ]),
    reloadData() {
      this.fetchProjectByID(this.project.ID).then(async response => {
        if (response) {
          this.project = response.data
          this.updateAllUsersWithCheckedProperty()
        }
      })
    },
    async handleChangeCompany(e) {
      await this.fetchUsers(e.companyID)
      this.updateAllUsersWithCheckedProperty()
    },
    openProjectUserModel(data) {
      if (this.company) {
        this.company = { ...this.company }
      }
      if (data) {
        this.projectUser = { ...data }
      }
      this.$bvModal.show('project-user-modal')
    },
    async fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanies
          if (this.project.ID) {
            this.company = this.companies.find(
              i => i.companyID === this.project.company_id,
            )
          } else if (this.companies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.company = this.companies[0]
          }
        }
      })
    },
    getMaxUserId(data) {
      const projectUsers = data.project_users

      // Check if the array is not empty
      if (projectUsers && projectUsers.length > 0) {
        // Extract an array of question IDs
        const userIds = projectUsers.map(user => user.ID)

        // Find the maximum ID
        return Math.max(...userIds)
      }
      // If survey_questions is empty, return a default value (e.g., 1)
      return 0
    },
    async updateAllUsersWithCheckedProperty() {
      const userIdsInProject = this.project.project_users.map(
        projectUser => projectUser.user.userID,
      )
      this.allUsers = this.allUsers.map(user => ({
        ...user,
        checked: userIdsInProject.includes(user.userID),
      }))
      this.selectdUsers = this.selectdUsers.map(user => ({
        ...user,
        checked: userIdsInProject.includes(user.userID),
      }))
    },
    filterUsersByConditions() {
      const queryLowered = this.searchUserQuery.toLowerCase()
      // Check conditions
      let filteredUsers
      if (this.searchUserQuery !== '') {
        filteredUsers = this.allUsers.filter(user => user.firstname.toLowerCase().includes(queryLowered)
      || user.lastname.toLowerCase().includes(queryLowered))
      } else {
        filteredUsers = [...this.allUsers.slice(0, 10)]
      }
      this.selectdUsers = filteredUsers
    },
    handleUserChange(user) {
      // eslint-disable-next-line no-param-reassign
      user.checked = !user.checked
      if (user.checked) {
        this.maxUserId += 1
        // Checkbox is checked, perform your action
        const objectUser = {
          ID: this.maxUserId,
          user_id: user.userID,
          is_new: true,
          is_project_admin: user.is_project_admin ? 1 : 0,
          role: user.role,
          project_id: this.project.ID,
          user,
        }
        this.createProjectUser({
          user_id: user.userID,
          role: user.role,
          is_project_admin: user?.is_project_admin,
          project_id: this.project.ID,
        })
        this.project.project_users.push(objectUser)
        this.updateAllUsersWithCheckedProperty()
      }
    },
    handleDeleteUser(userToRemove) {
      // eslint-disable-next-line no-param-reassign
      const indexToRemove = this.project.project_users.findIndex(
        user => user.ID === userToRemove.ID,
      )
      if (indexToRemove !== -1) {
        this.project.project_users.splice(indexToRemove, 1)
        this.updateAllUsersWithCheckedProperty()
        this.deleteProjectUser(userToRemove)
      }
    },
    async fetchUsers(companyId) {
      await this.fetchUsersByCompanyID(companyId).then(response => {
        this.allUsers = response
        this.selectdUsers = [...this.allUsers.slice(0, 10)]
      })
    },
    async handleEditUser(user) {
      await this.updateProjectUser(user)
      await this.reloadData()
    },
    uploadAllCompanyUsers() {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.PROJECT_ALL_CONFIRMATION')} ${this.project.name} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              id: this.project.ID,
              company_id: this.company.companyID,
            }
            this.uploadAllCompanyUsersToProject(payload).then(async response => {
              if (response) {
                this.successMessage(response.data)
                this.reloadData()
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
